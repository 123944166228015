.leftPanel {
    & .leftPanel-listItem {
        padding: 1em 1.25em !important;
    
        & .listText span {
            font-size: 15px;
            margin-left: 2em;
        }
    }

    & .listIcon {
        font-size: 18px;
    }

    & img.flag_icons {
        width: 25px;
        border-radius: 50%;
        overflow: hidden;
    }
}
