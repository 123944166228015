* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
}

*:focus { outline: 0; }

html { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); scroll-behavior: smooth; }

body {
    font-size: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #222;
    background: url("https://assets.resys.org/img/wavingbg.png");
}

body::-webkit-scrollbar-thumb { background-color: #00695c; border-radius: 10px; }
body::-webkit-scrollbar { width: 8px; background-color: #F7F7F7; }
body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F7F7F7; 
}